

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .pause {
      animation-play-state: paused;
    }
    ul, ol {
      list-style: revert;
    }
    li{
      margin-top: 5px;
    }
 }

@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');


* {
    box-sizing: border-box;
    overflow-x: hidden;
}

body {
    background:linear-gradient(to right,#234E70, #102738);
}

ion-icon {
    color: grey;
    font-size: 44px;
  }

  .loadingIcon{
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
  }
